<template>
    <v-container>
        <v-row dense v-if="tables.length === 1">
            <v-col cols="12">
                <v-text-field outlined dense :value="tables[0].table_name" hide-details :label="$t('generic.lang_tableName')"
                    @input="updateTableName"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-switch :label="$t('generic.lang_showTableName')" v-model="tableNameVisible" outlined dense hide-details
                    @change="updateTableNameVisibility"></v-switch>
            </v-col>
            <v-col cols="12">
                <v-switch :label="$t('generic.lang_reserveTableAfterBookingSeats')" v-model="tableReserveTableAfterBooking" :value="tables[0].reserveTableAfterBooking" outlined dense hide-details
                    @change="updateTableReserveAfterBooking"></v-switch>
            </v-col>
            <v-col cols="12">
                <v-text-field :min="0" :max="totalSeats" type="number" pattern="^[0-9]" :label="$t('generic.lang_minimumSeats')" v-model="tableMinBookableSeats" :value="tables[0].minBookableSeats" outlined dense hide-details
                    @change="updateTableMinBookableSeats" :rules="[rules.numberRange(tableMinBookableSeats, 0, totalSeats)]"></v-text-field>
            </v-col>
        </v-row>

        <v-row dense class="toolbox-row" v-if="tables.length === 1">
            <v-col cols="12">    
                <v-label>{{ $t('generic.lang_tableShape') }}</v-label>
            </v-col>
            <v-col cols="12" align="center" class="mb-2">
                <v-btn-toggle v-model="tableShape" mandatory :value="tables[0].shape.type" borderless @change="updateTable" large>
                    <v-btn value="rect" class="ma-0" large>
                        <v-icon center>
                            mdi-square
                        </v-icon>
                    </v-btn>
                    <v-btn value="circle" class="ma-0" large>
                        <v-icon center>
                            mdi-circle
                        </v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
            <template v-if="tables[0].shape.type === 'rect'">
                <v-col>
                    <v-text-field outlined dense v-model.number="xSeats" :min="xSeats + ySeats > 2 ? 0 : 1"
                        type="number" label="X Seats" @input="updateTable"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field outlined dense v-model.number="ySeats" :min="xSeats + ySeats > 2 ? 0 : 1"
                        type="number" label="Y Seats" @input="updateTable"></v-text-field>
                </v-col>
            </template>
            <v-col v-else>
                <v-text-field outlined dense v-model.number="numberOfSeats" :min="2" type="number"
                    :label="$t('eventbee.lang_numberOfSeats')" @input="updateTable"></v-text-field>
            </v-col>
        </v-row>

        <v-row dense class="seat-numbers">
            <v-col>
                <h5 class="group-title">{{ $t("eventbee.lang_seatNumbers") }}</h5>
                <v-row class="toolbox-row">
                    <v-col>
                        <label>{{ $t("generic.lang_numbering") }}</label>
                        <v-select outlined dense hide-details :items="seatNumberings"
                            item-text="label" item-value="id" class="toolbox-input" return-object
                            @change="updateSeatNumbering"></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="seatNumberingScheme" class="toolbox-row">
                    <v-col>
                        <label>{{ $t("generic.lang_startingAt") }}</label>
                        <v-text-field type="number" :min="1" outlined dense hide-details
                            class="toolbox-input" @input="updateSeatNumbering"></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="seatNumberingScheme" class="toolbox-row">
                    <v-col>
                        <v-switch outlined dense :label="$t('generic.lang_reverse')" hide-details class="toolbox-input"
                            @change="updateSeatNumbering"></v-switch>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { reverse, SEAT_NUMBERINGS } from "./numbering";
import { debounce } from "lodash";
import validation from "../../../mixins/validation/validation";

export default {
    name: 'TableToolbox',
    mixins: [validation],
    props: {
		tables: {
			type: Array,
			default: () => []
		},
	},
    data() {
        return {
            tableName: '',
            tableShape: this.tables[0].shape.type,
            tableShapes: ['rect', 'circle'],
            xSeats: this.tables[0].xSeats,
            ySeats: this.tables[0].ySeats,
            seatSpacing: 25,
            numberOfSeats: this.tables[0].numberOfSeats,
            tableNameVisible: this.tables[0].tableNameVisible,
            tableReserveTableAfterBooking: this.tables[0].reserveTableAfterBooking,
            tableMinBookableSeats: this.tables[0].minBookableSeats,
            seatNumberings: SEAT_NUMBERINGS,
			seatNumberingScheme: null,
			seatNumberingStartAt: 1,
			seatNumberingReversed: false,
        }
    },
    computed:{
        totalSeats(){
            if(this.tableShape === 'rect')
                return Number(this.xSeats) + Number(this.ySeats)

            return Number(this.numberOfSeats);
        }
    },
    methods: {
        updateTable: debounce(function() {
            this.$emit('update-table', {
                xSeats: this.xSeats,
                ySeats: this.ySeats,
                seats: this.numberOfSeats,
                type: this.tableShape
            })
        }, 100),
        updateTableName: debounce(function(value) {
            this.$emit('update-table:name', value)
        }, 100),
        updateTableNameVisibility: debounce(function(value) {
            this.$emit('update-table:name-visibility', value)
        }, 100),
        updateTableReserveAfterBooking: debounce(function(value) {
            this.$emit('updateTableSettings', {reserveTableAfterBooking: value})
        }, 100),
        updateTableMinBookableSeats: debounce(function(value) {
            this.$emit('updateTableSettings', {minBookableSeats: value})
        }, 100),
        setSeatSpacing: debounce(function(value) {
            this.$emit('update:seatSpacing', value)
        },100),
        updateSeatNumbering:debounce(function(){
			this.$emit('update-table:seat-numbering', {
				scheme: this.seatNumberingScheme,
				startAt: this.seatNumberingStartAt,
				reversed: this.seatNumberingReversed
			});
		},100),
    }
}
</script>