<template>
  <v-container fluid>
    <v-row dense class="toolbox-row">
      <v-col>
        <v-text-field
          v-model="planName"
          :label="$t('eventbee.lang_planName')"
          :value="initialPlanName"
          @input="updatePlanName"
          :rules="[rules.required]"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense class="toolbox-row">
      <v-divider class="mx-1"/><span class="mx-1">{{ $t("generic.lang_backgroundImage") }}</span><v-divider class="mx-1"/>
      
      <v-col align="center" cols="12" class="d-flex flex-column justify-center align-center">
        <v-btn
          v-if="backgroundImage"
          @click="removeBackgroundImage"
          color="error"
          elevation="0"
          width="80%"
          class="ml-2"
        >
          {{ $t('generic.lang_deleteImage') }}
        </v-btn>
        <template v-else>
          <v-btn
          @click="triggerFileInput"
          color="primary"
          width="80%"
          :disabled="!!backgroundImage"
        >
          {{ $t('generic.lang_uploadImage') }}
        </v-btn>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handleFileUpload"
          accept="image/*"
        >
        </template>
      </v-col>
    </v-row>
    <v-row dense class="toolbox-row" v-if="backgroundImage">
      <v-col cols="12"  class="d-flex flex-column justify-center align-center" >
        <v-btn
          @click="enterBgOverlayMode"
          elevation="0"
          width="80%"
          :color="toggleBackground?'indigo':''"
          :dark="toggleBackground"
          
        >
          {{ $t('generic.lang_edit') }} <v-icon size="15">mdi-image-edit-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="toggleBackground">
        <v-slider
          v-model="backgroundOpacity"
          :value="initialBackgroundOpacity"
          label="Background Opacity"
          @input="updateBackgroundOpacity"
          hide-details
          dense
          min="0.1"
          max="1"
          step="0.1"
        ></v-slider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validation from '../../../mixins/validation/validation';

export default {
  name: 'PlanToolBox',
  props: {
    toggleBackgroundProp: {
      type: Boolean,
      default: false
    },
    initialPlanName: {
      type: String,
    },
    initialBackgroundOpacity: {
      type: Number,
      default: 1
    },
    initialBackground:{
      type: String,
      default: "",
    }
  },
  mixins: [validation],
  data() {
    return {
      planName: this.initialPlanName,
      backgroundImage: this.initialBackground,
      backgroundOpacity: this.initialBackgroundOpacity,
      toggleBackground: false,
    }
  },
  watch:{
    toggleBackgroundProp(val){
      this.toggleBackground = val;
    },
    initialBackgroundOpacity (val){
      this.backgroundOpacity = val;
    },
    initialBackground (val){
      this.backgroundImage = val;
    },
  },
  methods: {
    updatePlanName(value) {
      this.$emit('update-plan-name', value);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.backgroundImage = e.target.result;
          this.$emit('update-background-image', e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    removeBackgroundImage() {
      this.backgroundImage = null;
      this.$emit('update-background-image', null);
    },
    updateBackgroundOpacity(value) {
      this.$emit('update-background-opacity', value);
    },
    enterBgOverlayMode() {
      this.toggleBackground = !this.toggleBackground
      this.$emit('enter-bg-overlay-mode', this.toggleBackground);
    }
  }
}
</script>