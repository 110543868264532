import { SEAT_NUMBERINGS, ROW_NUMBERINGS, ROW_NUMBER_POSITIONS, reverse } from './numbering';

export default {
    name: 'rowmixin',
    methods: {
        async updateRowNumberPosition(position) {
            await this.updateSelection(() => {
                if (!this.selectedRows.length) return;
                this.transformerGroup.clearCache();
                this.selectedRows.forEach(row => {
                    row.setAttr("row_number_position", position);
                    this.showRowNumber(row);
                });
                this.transformerGroup.cache();
                this.transformerLayer.batchDraw();
            }, true);
        },
        async updateRowNumber({row_number}) {
            if (!this.selectedRows.length) return;
            await this.updateSelection(() => {
                this.selectedRows[0].setAttr('row_number', row_number);
                this.selectedRows[0].rowNumberStart.text(row_number)
                this.selectedRows[0].rowNumberEnd.text(row_number)
            }, true)
        },
        async updateSeatNumbering({ scheme, startAt, reversed }) {
            if (!this.selectedRows.length) return;
            await this.updateSelection(() => {
                this.selectedRows.forEach(row => {
                    const seats = row.find('.seat');
                    let numbers = scheme.compute(seats, startAt);
                    if (reversed) numbers = reverse(numbers);

                    seats.forEach((seat, index) => {
                        const newNumber = numbers[index];
                        seat.setAttr('seat_number', newNumber);
                    });
                });
            })
        },
        async updateRowNumbering({ scheme, startAt, reversed }) {
            await this.updateSelection(() => {
                if (!this.selectedRows.length) return;
                const sortedRows = this.selectedRows.sort((a, b) => a.firstSeat.y() - b.firstSeat.y());
                let numbers = scheme.compute(sortedRows, startAt);
                if (reversed) numbers = reverse(numbers);
                sortedRows.forEach((row, index) => {
                    const newNumber = numbers[index];
                    row.row_number = newNumber;
                    row.setAttr('row_number', newNumber);
                    // Update row number texts
                    this.positionRowNumbers(row);
                });
            }, true);
        },
        async updateRowCurve(intensity) {

            // @TODO: needs some refinement

            await this.updateSelection(() => {
                if (!this.selectedRows.length) return;
        
                const calculateYOffset = (progress, rowWidth, intensity) => {
                    // Use a parabolic function to create a curve
                    // progress is expected to be between 0 and 1
                    const curveX = progress * 2 - 1; // Transform to range -1 to 1
                    return intensity * rowWidth * (curveX * curveX - 1) / 4;
                };
        
                const sortedRows = this.selectedRows.sort((a, b) => a.firstSeat.y() - b.firstSeat.y());
                let minX = Infinity, maxX = -Infinity;
                sortedRows.forEach(row => {
                    minX = Math.min(minX, row.firstSeat.x());
                    maxX = Math.max(maxX, row.lastSeat.x());
                });
        
                const totalWidth = maxX - minX;
        
                sortedRows.forEach((row) => {
                    const seats = row.find('.seat');
                    if (seats.length < 2) return;  // Skip rows with less than 2 seats
        
                    const firstSeat = seats[0];
                    const lastSeat = seats[seats.length - 1];
                    const rowWidth = totalWidth//lastSeat.x() - firstSeat.x();
        
                    // Apply the transformation to each seat
                    seats.forEach((seat, index) => {
                        if (index === 0) return;
        
                        const relativeX = seat.x() - firstSeat.x();
                        const progress = relativeX / rowWidth;
        
                        // Calculate vertical offset based on progress and intensity
                        const verticalOffset = calculateYOffset(progress, rowWidth, intensity);
        
                        // Apply new position
                        seat.y(firstSeat.y() + verticalOffset);
                    });
        
                    // Update row backbone
                    this.updateRowBackBone(row, true);
        
                    // Update row number positions
                    this.positionRowNumbers(row);
                });
            }, true);
        },
        async updateSeatSpacing(value) {
            await this.updateSelection(() => {
                let spacing = Math.max(value, 1);
                this.selectedRows.forEach(row => {
                    const seats = row.find('.seat');
                    if (seats.length < 2) return;
                    const newPositions = [seats[0].position()];

                    for (let i = 1; i < seats.length; i++) {
                        const prevSeat = seats[i - 1];
                        const currentSeat = seats[i];
                        const dxOld = currentSeat.position().x - prevSeat.position().x;
                        const dyOld = currentSeat.position().y - prevSeat.position().y;
                        const dOld = Math.sqrt(dxOld * dxOld + dyOld * dyOld);
                        const scale = spacing / dOld;

                        newPositions.push({
                            x: newPositions[i - 1].x + dxOld * scale,
                            y: newPositions[i - 1].y + dyOld * scale
                        });
                    }

                    seats.forEach((seat, index) => {
                        seat.position(newPositions[index]);
                    });
                    this.updateRowBackBone(row);
                    this.positionRowNumbers(row);
                });
            }, true);
        },
        async updateRowAlignment(alignment) {
            await this.updateSelection(() => {
                if (this.selectedRows.length < 2) return; // No need to align if there's only one row or less
                const sortedRows = this.selectedRows.sort((a, b) => a.firstSeat.y() - b.firstSeat.y());
                // Calculate the bounding box of all selected rows
                const boundingBox = sortedRows.reduce((box, row) => {
                    const seats = row.find('.seat');
                    const rowLeft = Math.min(...seats.map(seat => seat.absolutePosition().x));
                    const rowRight = Math.max(...seats.map(seat => seat.absolutePosition().x));
                    return {
                        left: Math.min(box.left, rowLeft),
                        right: Math.max(box.right, rowRight)
                    };
                }, { left: Infinity, right: -Infinity });
        
                const targetX = (() => {
                    switch (alignment) {
                        case 'start': return boundingBox.left;
                        case 'middle': return (boundingBox.left + boundingBox.right) / 2;
                        case 'end': return boundingBox.right;
                        default: return 0;
                    }
                })();
                // Align rows
                sortedRows.forEach(row => {
                    const seats = row.find('.seat');
                    const rowLeft = Math.min(...seats.map(seat => seat.absolutePosition().x));
                    const rowRight = Math.max(...seats.map(seat => seat.absolutePosition().x));
                    const rowCenter = (rowLeft + rowRight) / 2;
        
                    let offsetX;
                    switch (alignment) {
                        case 'start': offsetX = targetX - rowLeft; break;
                        case 'middle': offsetX = targetX - rowCenter; break;
                        case 'end': offsetX = targetX - rowRight; break;
                        default: offsetX = 0;
                    }
                    // Move all seats in the row
                    seats.forEach(seat => seat.absolutePosition({
                        x: seat.absolutePosition().x + offsetX,
                        y: seat.absolutePosition().y,
                    }));
        
                    // Update row backbone and number positions
                    this.updateRowBackBone(row, true);
                    this.positionRowNumbers(row);
                });
            }, true);
        },
        async updateRowSpacing(value) {
            let spacing = Math.max(value, 1);
            if (this.selectedRows.length < 2) return;

            await this.updateSelection(() => {
                // Sort rows based on the y-position of their first seat
                const sortedRows = this.selectedRows.sort((a, b) => a.firstSeat.y() - b.firstSeat.y());
                // Keep the first row fixed
                const firstRow = sortedRows[0];
                const firstRowY = firstRow.firstSeat.y();

                // Update positions of subsequent rows
                for (let i = 1; i < sortedRows.length; i++) {
                    const row = sortedRows[i];
                    const newY = firstRowY + spacing * i;
                    const offsetY = newY - row.firstSeat.y();

                    // Move all seats in the row
                    const seats = row.find('.seat');
                    for (let j = 0; j < seats.length; j++) {
                        seats[j].y(seats[j].y() + offsetY);
                    }

                    // Update row backbone and number positions
                    this.updateRowBackBone(row);
                    this.positionRowNumbers(row);
                }
            }, true);
        },
        async updateRowSkew(skewAmount) {
            await this.updateSelection(() => {
                if (!this.selectedRows.length) return;
        
                const sortedRows = this.selectedRows.sort((a, b) => a.firstSeat.y() - b.firstSeat.y());
                let minX = Infinity, maxX = -Infinity;
                sortedRows.forEach(row => {
                    minX = Math.min(minX, row.firstSeat.x());
                    maxX = Math.max(maxX, row.lastSeat.x());
                });
                sortedRows.forEach((row) => {
                    const seats = row.find('.seat');
                    if (seats.length < 2) return;  // Skip rows with less than 2 seats
        
                    const firstSeat = seats[0];
                    const lastSeat = seats[seats.length - 1];
                    
                    // Calculate the total horizontal distance of the row
                    const rowWidth = maxX - minX;
                    
                    // Apply the transformation to each seat
                    seats.forEach((seat, index) => {
                        if(index===0)
                            return
                        
                        const relativeX = seat.x() - row.firstSeat.x();
                        const progress = relativeX / rowWidth;
                        
                        // Calculate vertical offset based on progress and skew amount
                        const verticalOffset = progress * skewAmount;
                        
                        // Apply new position
                        seat.y(row.firstSeat.y() + verticalOffset);
                    });
                    
                    // Update row backbone
                    this.updateRowBackBone(row, true);
                    
                    // Update row number positions
                    this.positionRowNumbers(row);
                });
            }, true);
        },
    },
}