import { reverse } from './numbering';
import { COLORS, TABLE_WIDTH, TABLE_HEIGHT, SEAT_GAP, TABLE_RADIUS, SEAT_RADIUS } from './constants';
import randomString from 'randomstring';
import { hexToHexa } from './helpers';



export default {
    methods: {
        async updateTableName(name){
            if(this.selectedTables.length === 0) return;
            await this.updateSelection(() => {
                this.selectedTables[0].attrs.table_name = name;
                const text = this.selectedTables[0].tableNameText;
                text.text(name);
                text.offsetX(text.width() / 2);
                text.offsetY(text.height() / 2);
            }, true);
        },
        async updateTableNameVisibility(visible) {
            if (!this.selectedTables.length) return;
            await this.updateSelection(() => {
                this.selectedTables.forEach(table => {
                    table.attrs.tableNameVisible = visible;
                    table.tableNameText.visible(visible);
                });
            })
        },
        async updateTableSettings(config) {
            if (!this.selectedTables.length) return;
            await this.updateSelection(() => {
                this.selectedTables.forEach(table => {
                    if(config.hasOwnProperty('reserveTableAfterBooking')){
                        table.attrs.reserveTableAfterBooking = config.reserveTableAfterBooking;
                    }

                    if(config.hasOwnProperty('minBookableSeats')){
                        table.attrs.minBookableSeats = config.minBookableSeats;
                    }
                });
            })
        },
        async updateTableSeatNumbering({ scheme, startAt, reversed }) {
            if (!this.selectedTables.length) return;
            await this.updateSelection(() => {
                this.selectedTables.forEach(table => {
                    const seats = table.find('.table-seat');
                    let numbers = scheme.compute(seats, startAt);
                    if (reversed) numbers = reverse(numbers);

                    seats.forEach((seat, index) => {
                        const newNumber = numbers[index];
                        seat.setAttr('seat_number', newNumber);
                    });
                });
            })
        },
        async updateTable(updateData) {
            await this.updateSelection(() => {
                if(this.selectedTables.length === 0) return;
                const { type, seats, xSeats, ySeats } = updateData;
                
                // Find the existing table
                for(const tableGroup of this.selectedTables){
                    const oldTableData = tableGroup.attrs;
                    
                    const newTableData = this.generateTableData(oldTableData.table_name, oldTableData.x, oldTableData.y, type, seats, xSeats, ySeats);
                    newTableData.tableNameVisible = oldTableData.tableNameVisible;
                    
                    // Remove old table contents
                    tableGroup.destroyChildren();
                    tableGroup.setAttrs(newTableData);
                    // Create and add new table shape
                    let newTableShape;
                    if (newTableData.shape.type === "rect") {
                        newTableShape = new Konva.Rect({
                            name: "table-shape",
                            x: newTableData.shape.x,
                            y: newTableData.shape.y,
                            width: newTableData.shape.width,
                            height: newTableData.shape.height,
                            perfectDrawEnabled: false,
                            shadowForStrokeEnabled: false,
                            cornerRadius: 10,
                            fill: "white",
                            strokeWidth: 1,
                            stroke: hexToHexa(COLORS[7][0], 0.8),
                        });
                    } else {
                        newTableShape = new Konva.Circle({
                            name: "table-shape",
                            x: newTableData.shape.x,
                            y: newTableData.shape.y,
                            radius: newTableData.shape.radius,
                            perfectDrawEnabled: false,
                            shadowForStrokeEnabled: false,
                            fill: "white",
                            strokeWidth: 1,
                            stroke: hexToHexa(COLORS[7][0], 0.8),
                        });
                    }
                    tableGroup.add(newTableShape);
                    tableGroup.id(newTableShape.table_id);
                    tableGroup.tableNameText.id("table-name-" + newTableShape.table_id)
                    
                    // Add new seats
                    newTableData.seats.forEach((seat) => {
                        const seatGroup = this.addSeatGroup(seat, "table-seat");
                        tableGroup.add(seatGroup);
                    });
                    
                    this.updateTableNamePosition(tableGroup);
                }
            }, true)
        },
        generateTableData(name, posX, posY, type, numberOfSeats, xSeats, ySeats) {
            const pi = Math.PI;
            let table = {
                table_name: name,
                rotation: 0,
                numberOfSeats: numberOfSeats ? numberOfSeats : 4,
                xSeats: xSeats ? xSeats : 1, 
                ySeats: ySeats ? ySeats : 1,
                table_id: randomString.generate(10),
                tableNameVisible: true,
                minBookableSeats: 0,
                reserveTableAfterBooking: false,
                shape: {
                    type: type === "circle" ? "circle" : "rect",
                    width:
                        type === "rect"
                            ? Math.max(TABLE_WIDTH, xSeats * (2 * SEAT_RADIUS + SEAT_GAP))
                            : undefined,
                    height:
                        type === "rect"
                            ? Math.max(TABLE_HEIGHT, ySeats * (2 * SEAT_RADIUS + SEAT_GAP))
                            : undefined,
                    radius:
                        type === "circle"
                            ? Math.max(TABLE_RADIUS, SEAT_RADIUS * (numberOfSeats / pi))
                            : undefined
                },
                x: posX,
                y: posY,
                seats: []
            };

            if (type === "rect") {
                // Calculate dimensions
                const tableWidth = table.shape.width;
                const tableHeight = table.shape.height;

                // Center offset for xSeats and ySeats
                const xOffset =
                    (tableWidth - (xSeats * (2 * SEAT_RADIUS + SEAT_GAP) - SEAT_GAP)) / 2;
                const yOffset =
                    (tableHeight - (ySeats * (2 * SEAT_RADIUS + SEAT_GAP) - SEAT_GAP)) / 2;

                table.shape.x = SEAT_RADIUS * 2 + SEAT_GAP;
                table.shape.y = SEAT_RADIUS * 2 + SEAT_GAP;


                // Top seats
                for (let i = 0; i < xSeats; i++) {
                    table.seats.push({
                        seat_number: (table.seats.length + 1).toString(),
                        x: table.shape.x + xOffset + i * (2 * SEAT_RADIUS + SEAT_GAP) + SEAT_RADIUS,
                        y: table.shape.y - SEAT_RADIUS - SEAT_GAP,
                        seat_id: randomString.generate(10),
                        category: "",
                        rotation: 0,
                    });
                }

                // Right seats
                for (let i = 0; i < ySeats; i++) {
                    table.seats.push({
                        seat_number: (table.seats.length + 1).toString(),
                        x: table.shape.x + tableWidth + SEAT_RADIUS + SEAT_GAP,
                        y: table.shape.y + yOffset + i * (2 * SEAT_RADIUS + SEAT_GAP) + SEAT_RADIUS,
                        seat_id: randomString.generate(10),
                        category: "",
                        rotation: 0,
                    });
                }

                // Bottom seats
                for (let i = 0; i < xSeats; i++) {
                    table.seats.push({
                        seat_number: (table.seats.length + 1).toString(),
                        x: tableWidth + table.shape.x - xOffset - i * (2 * SEAT_RADIUS + SEAT_GAP) - SEAT_RADIUS,
                        y: table.shape.y + tableHeight + SEAT_RADIUS + SEAT_GAP,
                        seat_id: randomString.generate(10),
                        category: "",
                        rotation: 0,
                    });
                }

                // Left seats
                for (let i = 0; i < ySeats; i++) {
                    table.seats.push({
                        seat_number: (table.seats.length + 1).toString(),
                        x: table.shape.x - SEAT_RADIUS - SEAT_GAP,
                        y: tableHeight + table.shape.y - yOffset - i * (2 * SEAT_RADIUS + SEAT_GAP) - SEAT_RADIUS,
                        seat_id: randomString.generate(10),
                        category: "",
                        rotation: 0,
                    });
                }
            } else if (type === "circle") {
                const deg = (2 * pi) / numberOfSeats;
                const angleOffset = -pi / 2;
                for (let i = 0; i < numberOfSeats; i++) {
                    let angle = deg * i + angleOffset;
                    table.seats.push({
                        seat_number: (i + 1).toString(),
                        x: Math.cos(angle) * (table.shape.radius + SEAT_GAP + SEAT_RADIUS),
                        y: Math.sin(angle) * (table.shape.radius + SEAT_GAP + SEAT_RADIUS),
                        seat_id: randomString.generate(10),
                        category: "",
                        rotation: 0,
                    });
                }
            }

            return table;
        },
        renderTable(tableData) {
            let items = [];

            let table;
            if (tableData.shape.type === "rect") {
                table = new Konva.Rect({
                    name: "table-shape",
                    tableId: tableData.table_id,
                    x: tableData.shape.x,
                    y: tableData.shape.y,
                    width: tableData.shape.width,
                    height: tableData.shape.height,
                    perfectDrawEnabled: false,
                    shadowForStrokeEnabled: false,
                    cornerRadius: 10,
                    fill: "white",
                    strokeWidth: 2,
                    stroke: COLORS[7][0],
                });
            } else {
                table = new Konva.Circle({
                    name: "table-shape",
                    tableId: tableData.table_id,
                    x: tableData.shape.x,
                    y: tableData.shape.y,
                    radius: tableData.shape.radius,
                    perfectDrawEnabled: false,
                    shadowForStrokeEnabled: false,
                    fill: "white",
                    strokeWidth: 2,
                    stroke: COLORS[7][0],
                });
            }
            items.push(table);

            tableData.seats.forEach((seat) => {
                const seatGroup = this.addSeatGroup(seat, "table-seat");
                items.push(seatGroup);
            });

            const tableGroup = new Konva.Group({
                name: "table",
                ...tableData,
                draggable: true,
            });
            tableGroup.id(tableData.table_id);
            tableGroup.add(...items);

            let text = new Konva.Text({
                name: "table-name",
                tableId: tableData.table_id,
                text: tableData.table_name,
                fontSize: 16,
                align: "center",
                verticalAlign: "middle",
                padding: 10,
                visibile: tableData.tableNameVisible,
                listening: false,
            });

            text.id("table-name-" + tableData.table_id)

            text.offsetX(text.width() / 2);
            text.offsetY(text.height() / 2);


            tableGroup.tableNameText = text;

            this.updateTableNamePosition(tableGroup);

            this.overlayLayer.add(text);

            this.layer.add(tableGroup);
        },
    },
}