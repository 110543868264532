export default {
    data() {
        return {
            activeKeys: new Set(),
        }
    },
    methods: {
        handleKeyboardShortcuts(event) {
            if(!event)
                return;

            if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA'){
                return;
            }
            const key = event.key.toLowerCase();
            if (this.activeKeys.has(key)) {
                event.preventDefault();
                return;
              }

            this.activeKeys.add(key);
            
            if (event.key === 'Delete') {
                event.preventDefault();
                this.removeAllSelected();
            }


            if (event.key === 'Escape') {
                event.preventDefault();
                this.cancelAllActions();
            }

            if (event.ctrlKey || event.metaKey) {
                switch (key) {
                    case 'a':
                        event.preventDefault();
                        this.selectAll();
                        break;
                    case 'd':
                        
                        // this.duplicateSelected();
                        break;
                    case 'z':
                        event.preventDefault();
                        if (event.shiftKey) {
                            // this.redo();
                        } else {
                            // this.undo();
                        }
                        break;
                    case 'y':
                        event.preventDefault();
                        // this.redo();
                        break;
                    case 'c':
                        event.preventDefault();
                        this.copySelected();
                        break;
                    case 'v':
                        event.preventDefault();
                        this.pasteAtMousePosition();
                        break;
                    case 's':
                        event.preventDefault();
                        this.saveSeatingData();
                        break;
                }
            }
        },
        handleKeyUp(event) {
            const key = event.key.toLowerCase();
            this.activeKeys.delete(key);
        }
    },
    mounted() {
        // ... other mounted code ...
        window.addEventListener('keydown', this.handleKeyboardShortcuts);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyboardShortcuts);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyboardShortcuts);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
}