<template>
	<v-container fluid>
		<v-row v-if="rows.length > 1" dense class="toolbox-row">
			<v-col>
				<label>{{ $t("eventbee.lang_rowSpacing") }}</label>
				<v-slider loader-height="5" outlined dense hide-details thumb-label="always" v-model="rowSpacing" class="toolbox-input"
					:min="25" @input="setRowSpacing"></v-slider>
			</v-col>
		</v-row>
		<v-row dense class="toolbox-row">
			<v-col cols="12">
				<label>{{$t("generic.lang_curve")}}</label>
				<v-slider v-model="curveIntensity" min="-2" max="2" step="0.1" dense hide-details thumb-label="always"
					@input="updateRowCurve"></v-slider>
			</v-col>
			<v-col cols="12">
				<label>{{$t("generic.lang_skew")}}</label>
				<v-slider v-model="rowSkew" min="-100" max="100" step="1" dense hide-details thumb-label="always"
					@input="updateRowSkew"></v-slider>
			</v-col>
		</v-row>
		<v-row dense class="toolbox-row">
			<v-col>
				<label>{{ $t("eventbee.rowAlignment") }}</label>
				<v-btn-toggle @change="updateRowAlignment" borderless dense>
					<v-btn class="ma-0" value="start">{{ $t("generic.lang_start") }}</v-btn>
					<v-btn class="ma-0" value="middle">{{ $t("generic.lang_middle") }}</v-btn>
					<v-btn class="ma-0" value="end">{{ $t("generic.lang_end") }}</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>
		<v-row dense class="toolbox-row">
			<v-col>
				<label>{{ $t("eventbee.lang_seatSpacing") }}</label>
				<v-slider loader-height="20" outlined dense hide-details thumb-label="always" v-model="seatSpacing" :value="seatSpacing"
					:min="21" class="toolbox-input" @input="setSeatSpacing"></v-slider>
			</v-col>
		</v-row>
		<v-row dense class="toolbox-row" v-if="rows.length === 1">
			<v-col>
				<label>
					{{ $t("eventbee.lang_rowNumber") }}
				</label>
				<v-text-field outlined dense hide-details v-model="rowNumber" class="toolbox-input"
					@input="updateRowNumber"></v-text-field>
			</v-col>
		</v-row>
		<v-row dense class="toolbox-row">
			<v-col>
				<label>{{ $t("eventbee.lang_rowNumber") +' '+ $t("generic.lang_position") }}</label><br/>
				<v-btn-toggle v-model="rowNumberPosition" multiple borderless @change="updateRowNumberPosition" dense>
					<v-btn v-for="position of rowNumberPositions" :key="position.value" :value="position.value"
						class="ma-0">
						<v-icon center>
							{{ position.icon }}
						</v-icon>
					</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>
		<v-row class="row-numbers">
			<v-col>
				<h4 class="group-title">{{ $t("eventbee.lang_rowNumbers") }}</h4>
				<v-row dense class="toolbox-row">
					<v-col>
						<label>{{ $t("generic.lang_numbering") }}</label>
						<v-select outlined dense hide-details v-model="rowNumberingScheme" :items="rowNumberings"
							item-text="label" item-value="id" class="toolbox-input" return-object
							@change="updateRowNumbering"></v-select>
					</v-col>
				</v-row>
				<v-row dense v-if="rowNumberingScheme" class="toolbox-row">
					<v-col>
						<label>{{ $t("generic.lang_startingAt") }}</label>
						<v-slider outlined dense hide-details :min="0" :max="100" thumb-label="always" v-model="rowNumberingStartAt" class="toolbox-input"
							@input="updateRowNumbering"></v-slider>
					</v-col>
				</v-row>
				<v-row dense v-if="rowNumberingScheme" class="toolbox-row">
					<v-col>
						<v-switch outlined dense hide-details :label="$t('generic.lang_reverse')" v-model="rowNumberingReversed" class="mt-1"
							@change="updateRowNumbering"></v-switch>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="seat-numbers">
			<v-col>
				<h4 class="group-title">{{ $t("eventbee.lang_seatNumbers") }}</h4>
				<v-row dense class="toolbox-row">
					<v-col>
						<label>{{ $t("generic.lang_numbering") }}</label>
						<v-select outlined dense hide-details v-model="seatNumberingScheme" :items="seatNumberings"
							item-text="label" item-value="id" class="toolbox-input" return-object
							@change="updateSeatNumbering"></v-select>
					</v-col>
				</v-row>
				<v-row dense v-if="seatNumberingScheme">
					<v-col>
						<label>{{ $t("generic.lang_startingAt") }}</label>
						<v-slider outlined dense hide-details :min="0" :max="100" thumb-label="always" v-model="seatNumberingStartAt" class="toolbox-input"
							@input="updateSeatNumbering"></v-slider>
					</v-col>
				</v-row>
				<v-row dense v-if="seatNumberingScheme">
					<v-col>
						<v-switch outlined dense hide-details :label="$t('generic.lang_reverse')" v-model="seatNumberingReversed" class="mt-1"
							@change="updateSeatNumbering"></v-switch>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>


<script>
import { set } from "lodash";
import { reverse, ROW_NUMBERINGS, SEAT_NUMBERINGS, ROW_NUMBER_POSITIONS } from "./numbering";
import { debounce } from "lodash";

const shallowEqual = (object1, object2) => {
	if (object1 === null || object2 === null || typeof object1 !== "object" || typeof object2 !== "object") {
		return object1 === object2
	}
	const keys1 = Object.keys(object1)
	const keys2 = Object.keys(object2)
	if (keys1?.length !== keys2?.length) {
		return false
	}
	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false
		}
	}
	return true
}

const groupValue = (rows, mapper) => {
	let lastFound = undefined
	for (let row of rows) {
		let val = mapper(row)
		if (lastFound === undefined) {
			lastFound = val
		} else if (!shallowEqual(lastFound, val)) {
			return undefined
		}
	}
	return lastFound
}

const round = (fl, places) => Number(fl.toFixed(places ? places : 0))

export default {
	props: {
		rows: Array,
	},
	data() {
		return {
			rowNumberPositions: ROW_NUMBER_POSITIONS,
			rowNumberings: ROW_NUMBERINGS,
			seatNumberings: SEAT_NUMBERINGS,
			seatNumberingScheme: null,
			seatNumberingStartAt: 1,
			seatNumberingReversed: false,
			rowNumberingScheme: null,
			rowNumberingStartAt: 1,
			curveIntensity: 0,
			rowSpacing: 25,
			seatSpacing: 25,
			rowNumber: this.rows[0].row_number,
			rowSkew: 0,
			rowNumberingReversed: false,
			rowNumberPosition: [],
		}
	},
	methods: {
		updateRowSkew: debounce(function (value) {
			this.$emit('update-row-skew', value);
		}, 100),
		updateSeatNumbering: debounce(function () {
			this.$emit('update-seat-numbering', {
				scheme: this.seatNumberingScheme,
				startAt: this.seatNumberingStartAt,
				reversed: this.seatNumberingReversed
			});
		}, 100),
		updateRowNumbering: debounce(function () {
			this.$emit('update-row-numbering', {
				scheme: this.rowNumberingScheme,
				startAt: this.rowNumberingStartAt,
				reversed: this.rowNumberingReversed
			});
		}, 100),
		updateRowCurve: debounce(function (value) {
			this.$emit('update-row-curve', value);
		}, 100),
		updateRowAlignment: debounce(function (alignment) {
            this.$emit('update-row-alignment', alignment);
        }, 100),
		updateRowNumber:debounce(function(val) {
			this.$emit('update-row:number', {
				row_number: val
			});
		},100),
		updateRowNumberPosition:debounce(function(val) {
			this.$emit('update-row:number-position', val);
		},100),
		/**
		 * @TODO
		 * implement add seat and add row
		 */
		setRowSpacing: debounce(function(val) {
			this.$emit('update:rowSpacing', val);
		}, 100),
		setSeatSpacing:debounce(function(val) {
			this.$emit('update:seatSpacing', val);
		}, 100),
	}
}
</script>