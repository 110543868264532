const COLORS = [
    ["#1FBC9C", "#1CA085"],
    ["#2ECC70", "#27AF60"],
    ["#3398DB", "#2980B9"],
    ["#A463BF", "#8E43AD"],
    ["#3D556E", "#222F3D"],
    ["#F2C511", "#F39C19"],
    ["#E84B3C", "#C0382B"],
    ["#DDE6E8", "#BDC3C8"]
]
const SEAT_SPACE = 25;
const SEAT_RADIUS = 10;
const ROW_SPACE = 25;
const SEAT_GAP = 5;
const TABLE_RADIUS = 20;
const TABLE_WIDTH = 50;
const TABLE_HEIGHT = 50;
const MAX_SEATS = 10000;


// Limits for the position of the this.stage
const STAGE_EXTENT = [[-4000, -4000], [4000, 4000]] // [minX, minY, maxX, maxY]
const ZOOM_LIMITS = [0.2, 1];// [minZoom, maxZoom]
const ZOOM_STEP = 0.1;// Zoom step for the zoom in/out buttons 0 to 1
const SHOW_TEXT_THRESHOLD = 0.7;
const SHOW_SEAT_NUMBER_THRESHOLD = 0.6;
const CACHE_THRESHOLD = 0.8;
const PREVIEW_CACHE_THRESHOLD = 1.2;


const GRID_SIZE = 25; // Size of grid cells
const SNAP_THRESHOLD = 25; // Distance threshold for snapping
const SNAP_ANGLES = Array.from({ length: 16 }, (_, i) => i * 22.5);
const SNAP_ANGLES_THRESHOLD = 20; // degrees
export {
    COLORS,
    SEAT_SPACE,
    SEAT_RADIUS,
    ROW_SPACE,
    SEAT_GAP,
    TABLE_RADIUS,
    TABLE_WIDTH,
    TABLE_HEIGHT,
    MAX_SEATS,
    STAGE_EXTENT,
    ZOOM_LIMITS,
    ZOOM_STEP,
    SHOW_TEXT_THRESHOLD,
    CACHE_THRESHOLD,
    GRID_SIZE,
    SHOW_SEAT_NUMBER_THRESHOLD,
    PREVIEW_CACHE_THRESHOLD,
    SNAP_ANGLES,
    SNAP_ANGLES_THRESHOLD,
    SNAP_THRESHOLD
}