import { COLORS, ROW_SPACE, SEAT_RADIUS, SEAT_SPACE } from "./constants";
import randomString from "randomstring";

export default {
    methods: {
        async updateSeat(updateData) {
            await this.updateSelection(() => {
                if (this.selectedSeats.length < 2) {
                    if (updateData.hasOwnProperty('seat_number')) {
                        this.selectedSeats.forEach(seat => {
                            seat.setAttrs({
                                seat_number: updateData.seat_number
                            })
                        })
                    }
                }
            });
        },
        async updateSeatCategory(category) {
            await this.updateSelection(() => {
                this.selectedSeats.forEach(seat => {
                    seat.setAttrs({
                        fill: category?.id ? category.color : "white",
                        categoryColor: category?.id ? category.color : "white",
                        category: category?.id ? category.id : "",
                    })
                });
            });
        },
        generateRowOfSeatsFromLine(start, end) {
            // calculate number of seats
            const numberOfSeats = this.calculateNumberOfSeats(start, end);


            const row = {
                x: 0,
                y: 0,
                row_id: randomString.generate(10),
                row_number: 1,
                row_number_position: ["Start", "End"],
                seats: []
            };

            const seatSpacingX = (end.x - start.x) / numberOfSeats;
            const seatSpacingY = (end.y - start.y) / numberOfSeats;

            for (let seatIndex = 0; seatIndex < numberOfSeats; seatIndex++) {
                const seatX = start.x + seatIndex * seatSpacingX;
                const seatY = start.y + seatIndex * seatSpacingY;
                const seat = {
                    seat_number: (seatIndex + 1).toString(),
                    seat_id: randomString.generate(10),
                    row_id: row.row_id,
                    seatIndex,
                    x: seatX,
                    y: seatY,
                    category: ""
                };
                row.seats.push(seat);
            }

            return row;
        },
        generateRowsAndSeats(x, y, gridWidth, gridHeight) {
            const rows = [];

            for (let rowIndex = 0; rowIndex < gridHeight; rowIndex++) {
                const rowY = y + rowIndex * ROW_SPACE + SEAT_RADIUS;
                const row = {
                    x: 0,
                    y: 0,
                    rotation: 0,
                    row_id: randomString.generate(10),
                    row_number: rowIndex + 1,
                    row_number_position: ["Start", "End"], // left middle right
                    seats: []
                };

                for (let seatIndex = 0; seatIndex < gridWidth; seatIndex++) {
                    const seatX = x + seatIndex * SEAT_SPACE;
                    const seat = {
                        seat_number: (seatIndex + 1).toString(),
                        seat_id: randomString.generate(10),
                        row_id: row.row_id,
                        seatIndex,
                        x: seatX,
                        rotation: 0,
                        y: rowY,
                        category: ""
                    };
                    row.seats.push(seat);
                }

                rows.push(row);
            }

            return rows;
        },
    }
}