<template>
    <v-container class="c-seat-tools">
        <v-row dense v-if="seats.length === 1" class="toolbox-row">
            <v-col>
                <label>
                    Seat number
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>This is the number of the seat that will be shown to users.</span>
                    </v-tooltip>
                </label>
                <!-- <v-text-field v-model="seatNumber" class="toolbox-input" @input="setSeatNumber"></v-text-field> -->
                <v-text-field hide-details dense outlined v-model="seatNumber" class="toolbox-input"
                    @input="updateSeatNumber" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const groupValue = (seats, mapper) => {
    const uniques = seats.map(mapper).filter((v, i, s) => s.indexOf(v) === i)
    if (uniques.length === 1) {
        return uniques[0]
    } else {
        return undefined
    }
}

export default {
    name: 'SeatToolBox',
    props: {
        seats: {
            type: Array,
            default: () => []
        },
        seatCategories: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            seatNumber: this.seats[0].seat_number,
        }
    },
    methods: {
        updateSeatNumber(value) {
            this.$emit('update-seat', { seat_number: value });
        },
    }
}
</script>