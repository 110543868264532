<!-- AreaToolBox.vue -->
<template>
    <v-container class="c-area-tools">
        <v-row dense class="toolbox-row">
            <v-col cols="12">
                <v-text-field v-model="areaText" label="Area Text" @input="updateAreaText" dense
                outlined></v-text-field>
                <v-switch v-model="textVisible" label="Show Text" @change="updateTextVisibility"></v-switch>
            </v-col>
            <v-col cols="12">
                <v-slider label="Text size" @input="updateTextSize" min="16" max="45" step="1"></v-slider>
            </v-col>
            <v-col cols="12">
                <v-color-picker v-model="textColor" label="Text Color" @input="updateTextColor" :swatches="swatches"
                    hide-mode-switch show-swatches mode="hexa" ></v-color-picker>
            </v-col>
        </v-row>
        <v-divider/>
        <v-row dense class="toolbox-row">
            <v-col cols="12">
                <label>Shape</label>
                <v-color-picker v-model="backgroundColor" label="Background Color" @input="updateBackgroundColor" :swatches="swatches"
                     hide-mode-switch show-swatches mode="hexa" ></v-color-picker>
            </v-col>
            <v-col cols="12">
                <label>broder</label>
            </v-col>
            <v-col cols="12" v-if="isRectangle">
                <v-slider v-model="cornerRadius" label="Corner Radius" @input="updateCornerRadius" min="0" max="50"
                    step="1"></v-slider>
            </v-col>
            <v-col cols="12">
                <v-slider v-model="strokeWidth" label="Stroke Width" @input="updateStrokeWidth" min="0" max="10"
                    step="1"></v-slider>
            </v-col>
            <v-col cols="12">
                <v-color-picker @input="updateStrokeColor" v-model="strokeColor" label="Stroke Color" :swatches="swatches"
                     hide-mode-switch show-swatches mode="hexa" ></v-color-picker>
            </v-col>
        </v-row>
        <v-divider/>
        <v-row dense class="toolbox-row">
            <v-col cols="12">
                <label>Icon</label>
                <v-switch v-model="iconVisible" label="Show Icon" @change="updateIconVisibility"></v-switch>
            </v-col>
            <template v-if="iconVisible">
                <v-col cols="12">
                    <fontawesome-picker :fieldLabel="$t('generic.lang_chooseIcon')" dense @input="updateIcon"
                        v-model="selectedIcon"></fontawesome-picker>
                </v-col>
                <v-col cols="12">
                    <v-slider v-model="iconScale" label="Icon Scale" @input="updateIconScale" min="0.01" max="0.2"
                        step="0.01"></v-slider>
                </v-col>
                <v-col cols="12">
                    <v-color-picker v-model="iconColor" label="Icon Color" @input="updateIconColor" :swatches="swatches"
                         hide-mode-switch show-swatches mode="hexa" ></v-color-picker>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { debounce } from 'lodash';
import FontawesomePicker from "@/components/common/iconPicker";
import { COLORS } from './constants';

export default {
    name: 'AreaToolBox',
    components: {FontawesomePicker},
    props: {
        areas: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            swatches: COLORS,
            areaText: this.areas[0].areaText,
            textColor: this.areas[0].textColor,
            textVisible: this.areas[0].textVisible,
            backgroundColor: this.areas[0].backgroundColor,
            cornerRadius: this.areas[0].cornerRadius,
            strokeWidth: this.areas[0].strokeWidth,
            strokeColor: this.areas[0].strokeColor,
            iconVisible: this.areas[0].iconVisible,
            iconColor: this.areas[0].iconColor,
            selectedIcon: this.areas[0].icon,
            iconScale: this.areas[0].iconScale,
        }
    },
    computed: {
        isRectangle() {
            return this.areas[0]?.isRectangle;
        }
    },
    methods: {
        updateAreaText: debounce(function (value) {
            this.$emit('update-area', { text: value });
        }, 300),
        updateBackgroundColor: debounce(function (value) {
            this.$emit('update-area', { backgroundColor: value });
        }, 300),
        updateTextColor: debounce(function (value) {
            this.$emit('update-area', { textColor: value });
        }, 300),
        updateCornerRadius: debounce(function (value) {
            this.$emit('update-area', { cornerRadius: value });
        }, 300),
        updateStrokeWidth: debounce(function (value) {
            this.$emit('update-area', { strokeWidth: value });
        }, 300),
        updateStrokeColor: debounce(function (value) {
            this.$emit('update-area', { strokeColor: value });
        }, 300),
        updateTextVisibility: debounce(function (value) {
            this.$emit('update-area', { textVisible: value });
        }, 300),
        updateIconVisibility: debounce(function (value) {
            this.$emit('update-area', { iconVisible: value });
        }, 300),
        updateIconColor: debounce(function (value) {
            this.$emit('update-area', { iconColor: value });
        }, 300),
        updateIcon: debounce(function (value) {
            this.$emit('update-area', { iconName: value });
        }, 300),
        updateIconScale: debounce(function (value) {
            this.$emit('update-area', { iconScale: value });
        }, 300),
        updateTextSize: debounce(function (value) {
            this.$emit('update-area', { textSize: value });
        }, 300),
    }
}
</script>