<template>
    <div>
        <div class="px-2">
            <label class="ma-0">{{ $t('eventbee.lang_toggleFocalPoint') }} <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on" color="indigo">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t("eventbee.lang_focalPointTTip") }}</span>
            </v-tooltip></label>
            <v-switch @change="$emit('toggleFocalPoint', $event)" class="ma-0" hide-details dense />
        </div>
        <label class="ml-4">
            {{ $t("eventbee.lang_seatCategories") }}
        </label>
        <v-list dense>
            <v-list-item dense>
                <v-list-item-avatar size="30" color="grey lighten-3">
                    <v-icon size="20">mdi-cancel</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ $t("generic.lang_unassigned") }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ categoryInsights.unassigned.count }}/{{ seats.length }} - {{
                            categoryInsights.unassigned.percentage }}%
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn v-if="hasSelection" small icon @click="updateCategory(null)" class="ma-0">
                        <v-icon color="grey">mdi-plus</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-list-item dense v-for="category in seatCategories" :key="category.id">
                <v-list-item-avatar :color="category.color" size="30" small>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ category.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ categoryInsights[category.id].count }}/{{ seats.length }} - {{
                            categoryInsights[category.id].percentage
                        }}%
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn v-if="hasSelection" small icon @click="updateCategory(category)" class="ma-0">
                        <v-icon color="grey">mdi-plus</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>

export default {
    name: "PricingToolBox",
    props: {
        seats: {
            type: Array,
            default: () => []
        },
        seatCategories: {
            type: Array,
            default: () => []
        },
        hasSelection: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        categoryInsights() {
            const totalSeats = this.seats.length;
            const insights = {
                unassigned: { count: 0, percentage: 0 }
            };

            // Initialize insights for each category
            this.seatCategories.forEach(category => {
                insights[category.id] = { count: 0, percentage: 0 };
            });

            // Count seats for each category
            this.seats.forEach(seat => {
                if (seat.category) {
                    insights[seat.category].count++;
                } else {
                    insights.unassigned.count++;
                }
            });

            // Calculate percentages
            Object.keys(insights).forEach(key => {
                insights[key].percentage = ((insights[key].count / totalSeats) * 100).toFixed(0);
            });

            return insights;
        }
    },
    methods: {
        updateCategory(value) {
            this.$emit('update-seat-category', value);
        },
        updateAssignedCategories(){
            
        }
    }
}

</script>