<template>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" x="15" y="15" rx="3" fill="transparent" :stroke="color" stroke-width="2"/>
        <circle r="4" cx="25" cy="5" fill="transparent" :stroke="color" stroke-width="1" />
        <circle r="4" cx="5" cy="25" fill="transparent" :stroke="color" stroke-width="1" />
        <circle r="4" cx="45" cy="25" fill="transparent" :stroke="color" stroke-width="1" />
        <circle r="4" cx="25" cy="45" fill="transparent" :stroke="color" stroke-width="1" />
    </svg>
</template>

<script>
export default {
    name: "TableRectIcon",
    props:{
        color: {
            type: String,
            default: "black"
        },
    }
}
</script>