<template>
  <v-sheet v-show="show" :color="color" :style="sheetStyle" elevation="4" rounded width="250">
    <v-card-text class="px-2 py-1">
      <div v-if="rowNumber">{{ $t('eventbee.lang_row') }}: {{ rowNumber }}</div>
      <div v-if="tableName">{{ $t('generic.lang_table') }}: {{ tableName }}</div>
      <div>{{ $t('eventbee.lang_seat') }}: {{ seatNumber }}</div>
    </v-card-text>
    <div class="bg-warning text-white rounded-sm px-1" v-if="minBookableSeats > 1">
      {{ $t('eventbee.lang_bookAtLeast').replace('{seats}', minBookableSeats) }}
    </div>
  </v-sheet>
</template>
  
  <script>

  export default {
    name: 'SeatTooltip',
    computed:{
      sheetStyle(){
        return {
          position: 'absolute',
          zIndex: 1000,
          left: `${this.x + 20}px`,
          top: `${this.y + 20}px`,
        }
      }
    },
    props: {
      show: Boolean,
      color: String,
      x: Number,
      y: Number,
      minBookableSeats: Number,
      rowNumber: [String, Number],
      tableName: [String, Number],
      seatNumber: [String, Number],
    },
  }
  </script>