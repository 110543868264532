import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { COLORS } from './constants';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons';
import randomString from "randomstring"

export default {
    methods: {
        async updateArea(updateData) {
            if (this.selectedAreas.length === 0) return;
            this.selectedAreas.forEach(shape => {
                const content = shape.contentGroup;
                if (!content) return;
                const icon = content?.findOne('.area-icon');
                const text = content?.findOne('.area-text');

                if (updateData.hasOwnProperty('textVisible')) {
                    text.visible(updateData.textVisible);
                    shape.attrs.textVisible = updateData.textVisible;
                }

                if (updateData.hasOwnProperty('text')) {
                    text.text(updateData.text);
                }

                if (updateData.hasOwnProperty('textColor')) {
                    text.fill(updateData.textColor);
                }
                if (updateData.hasOwnProperty('textSize')) {
                    text.fontSize(updateData.textSize);
                }


                if (updateData.hasOwnProperty('backgroundColor')) {
                    shape.fill(updateData.backgroundColor);
                }
                if (updateData.hasOwnProperty('cornerRadius') && shape.getClassName() === 'Rect') {
                    shape.cornerRadius(updateData.cornerRadius);
                }
                if (updateData.hasOwnProperty('strokeWidth')) {
                    shape.strokeWidth(updateData.strokeWidth);
                }
                if (updateData.hasOwnProperty('strokeColor')) {
                    shape.stroke(updateData.strokeColor);
                }


                if (updateData.hasOwnProperty('iconVisible')) {
                    icon.visible(updateData.iconVisible);
                }
                if (updateData.hasOwnProperty('iconColor')) {
                    icon.fill(updateData.iconColor);
                }
                if (updateData.hasOwnProperty('iconName')) {
                    const iconDefinition = findIconDefinition({ iconName: updateData.iconName, prefix: 'fal' });
                    if (iconDefinition) {
                        icon.data(iconDefinition.icon[4]);
                        icon.attrs.iconName = updateData.iconName;
                    }
                }
                if (updateData.hasOwnProperty('iconScale')) {
                    icon.scale({ x: updateData.iconScale, y: updateData.iconScale });
                }

                this.updateAreaContentPosition(shape, content);
            });
        },
        generateArea(type) {
            const { x, y } = this.stage.getRelativePointerPosition();
            const areaId = randomString.generate(10);

            return {
                id: areaId,
                x: x,
                y: y,
                aread_id: areaId,
                isRectangle: type === "rect",
                rotation: 0,
                scaleX: 1,
                scaleY: 1,
                width: type === "rect" ? 300 : undefined,
                height: type === "rect" ? 100 : undefined,
                radius: type !== "rect" ? 100 : undefined,
                cornerRadius: type === "rect" ? 0 : undefined,
                fill: COLORS[7][0],
                stroke: COLORS[7][1],
                strokeWidth: 2,

                iconName: 'microphone',
                iconColor: COLORS[2][0],
                iconVisible: true,
                iconSize: 0.05,

                text: `Area 51`,
                textColor: COLORS[2][0],
                textSize: 25,
                textVisible: true,
            }
        },
        renderArea(areaData) {
            let shape;
            if (areaData.isRectangle) {
                shape = new Konva.Rect({
                    x: areaData.x,
                    y: areaData.y,
                    width: areaData.width,
                    height: areaData.height,
                    fill: areaData.fill,
                    stroke: areaData.stroke,
                    strokeWidth: areaData.strokeWidth,
                    cornerRadius: areaData.cornerRadius,
                    scaleX: areaData.scaleX,
                    scaleY: areaData.scaleY,
                    rotation: areaData.rotation,
                    name: 'area-shape',
                    strokeScaleEnabled: false,
                    perfectDrawEnabled: false,
                    shadowForStrokeEnabled: false,
                    hitStrokeWidth: 0,
                    draggable: true,
                });
            } else {
                shape = new Konva.Circle({
                    x: areaData.x,
                    y: areaData.y,
                    radius: areaData.radius,
                    fill: areaData.fill,
                    stroke: areaData.stroke,
                    strokeWidth: areaData.strokeWidth,
                    scaleX: areaData.scaleX,
                    scaleY: areaData.scaleY,
                    rotation: areaData.rotation,
                    name: 'area-shape',
                    strokeScaleEnabled: false,
                    perfectDrawEnabled: false,
                    shadowForStrokeEnabled: false,
                    hitStrokeWidth: 0,
                    draggable: true,
                });
            }
            this.layer.add(shape);

            const contentGroup = new Konva.Group({
                name: 'area-content',
                listening: false,
            });

            const iconDefinition = findIconDefinition({ iconName: areaData.iconName, prefix: 'fal' });
            const icon = new Konva.Path({
                name: 'area-icon',
                data: iconDefinition ? iconDefinition.icon[4] : faAlarmClock.icon[4],
                fill: areaData.iconColor,
                scaleX: areaData.iconSize,
                scaleY: areaData.iconSize,
                visible: areaData.iconVisible,
                listening: false,
            });

            const text = new Konva.Text({
                name: 'area-text',
                text: areaData.text,
                fontSize: areaData.textSize,
                fill: areaData.textColor,
                align: 'center',
                verticalAlign: 'middle',
                visible: areaData.textVisible,
                listening: false,
            });

            contentGroup.add(icon);
            contentGroup.add(text);
            shape.contentGroup = contentGroup;

            this.overlayLayer.add(contentGroup);
            shape.contentGroup = contentGroup;

            this.updateAreaContentPosition(shape, contentGroup);

            this.layer.batchDraw();
            this.overlayLayer.batchDraw();
        },
        updateAreaContentPosition(shape, contentGroup) {
            const areaDims = shape.getClientRect();
            const areaPos = shape.getClientRect();
            const icon = contentGroup.findOne('.area-icon');
            const text = contentGroup.findOne('.area-text');
            const iconRect = icon.getClientRect();
            // Position icon
            icon.position({
                x: -iconRect.width / 2,
                y: 0,
            });

            // Position text
            text.position({
                x: 0,
                y: iconRect.height,
            });

            // Center align text
            text.offsetX(text.width() / 2);

            // Calculate content size
            const contentHeight = contentGroup.getClientRect().height;

            // Calculate the center point of the rotated shape
            const centerX = areaPos.x + areaDims.width / 2;
            const centerY = areaPos.y + areaDims.height / 2;

            // Position content group at the center of the rotated shape
            contentGroup.absolutePosition({
                x: centerX,
                y: centerY - contentHeight / 2, // Adjust Y to center vertically
            });
            contentGroup.cache();
        },
    }
}