// converts a hex color to hex with alpha
const hexToHexa = (hex, opacity)=> {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r, g, b;

    if (hex.length === 3) {
        // Convert 3-digit hex to 6-digit hex
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error(`Invalid hex color.${hex}`);
    }

    // Ensure opacity is within bounds
    if (opacity < 0 || opacity > 1) {
        throw new Error('Opacity must be a value between 0 and 1.');
    }

    // Convert opacity to a 2-digit hex value
    const alpha = Math.round(opacity * 255).toString(16).padStart(2,'0');

    // Return the combined hex color with alpha
    return `#${hex}${alpha}`;
}

export {
    hexToHexa
}